import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "Veterans in Recruiting Funnels",
  "author": "Purepost",
  "date": "2018-04-11T05:26:52.000Z",
  "categories": ["Job Seeking"],
  "slug": "veterans-in-recruiting-funnels",
  "draft": false,
  "meta_title": "Veterans in Recruiting Funnels",
  "cover": "../../images/wp_blog_images/Funnel-FB-image1.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`How do Veterans fit into a Civilian Recruiting Funnel?`}</strong></p>
    <p>{`Veterans are no strangers to strategy, models, organizational structures, and formal processes focused on goals or outcomes. Whether this brings any comfort or not, the civilian world does function very similarly in that capacity, especially in the job selection and recruiting arenas.`}</p>
    <p>{`(The Army actually utilizes a version of a recruiting funnel for its own recruiting and selection process too.)`}</p>
    <p><strong parentName="p">{`So what is a recruiting funnel?`}</strong></p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1200px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "45.9375%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAACHElEQVQoz2WQ20tUURTG5x/rwXoooiI18zLmtcw0b+kkppVJViiEPSTTKJnjrSLo5jTqOBpeS7IwKCiZ3sYEL+OM58y57vOLOUeFasPHXt/eHz/WWq7NzU2i0Shra2v/KfUei8X49xhCZ/h7L91fOulZ6cK30sXDzx22XKurP3nk9eF/MsTgwDB+/xAD/mH6+wbx+XqI/IqgaRqJRAJJklBkBUnbpX2xiZbZalpma6gOF5H1+giZr9JwKZpCe6iexkA+baEK7oZruDVRjieQR+dUM5qhIYSBbmgk1B0kdRfFSNI6X0/j+3Lqpkq5MJZFSTCDc4ETuFIjeJfGOfr4PnnPvOQ/95H71Gv7vuVJTEtnS5aIKzLbchzN1NBMlWszlykbP0tFyE1lyG3XxcF0B7j8Q+LU1Q/kXF+i5PYy2S1LZDR95GskSVI1WY+pbMQ1tiTd3qFqKtyYu0J1uJDacLENvTSRy/mxMw5QGAa9jS/pKOynu/YF99x99LeOkpRVdrZl4lsyiVgSLOEADYXmmSoujmdTPpFjqySY7oxsmU4o4Q8RTfPwO+cO0cMedkemSf0YG3H09RhCCIRlHXSY2mFlKJ+qyQIb5h49vge0LKy9oDwyxfqhOpJvFm1vGeaBbH8AVGmYLqPo3WlKg5kUBE7add7bY87IlnCCQlJQFr4hVP0vwH6973Wh8+BTOzfn6mhb8NA232Dfqa7/ALnuSTIVCzIfAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Funnel FB image1",
        "title": "Funnel FB image1",
        "src": "/static/2fa6df1e4caac0c1d36acb3f57b97c8a/c1b63/Funnel-FB-image1.png",
        "srcSet": ["/static/2fa6df1e4caac0c1d36acb3f57b97c8a/72799/Funnel-FB-image1.png 320w", "/static/2fa6df1e4caac0c1d36acb3f57b97c8a/6af66/Funnel-FB-image1.png 640w", "/static/2fa6df1e4caac0c1d36acb3f57b97c8a/c1b63/Funnel-FB-image1.png 1200w"],
        "sizes": "(max-width: 1200px) 100vw, 1200px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <p>{`In its most basic form, it’s a strategy, often represented in a visual form. Think of it as a pipeline (or a process) that channels in a group of candidates (passive or active, depending on the recruiter and position looking to be filled) at the top. These candidates go through a good screening process in the middle that provides recruiters the opportunity to move the most talented applicants quickly to the bottom of the funnel to select, interview, and hire the best fitting applicant (or applicants) from that initial grouping that entered the funnel at the top.`}</p>
    <p>{`More than likely, as you traverse the job market, you’ll end up in one of these funnels along the way. So it’s important to understand how it works and how you can be the best candidate to make it to the end of the funnel for consideration and employment. There are several indicators that can assist in that success rate that we’ll discuss in an upcoming blog, but one to highlight now is a great `}<a parentName="p" {...{
        "href": "http://www.purepost.co/"
      }}>{`resume`}</a>{`. Our mission at Purepost is to ensure veterans are equipped with the best translation of their time in service which places them in the funnel at a competitive level. If you have not yet utilized the free resume translation service we provide, we invite you to get started today!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      